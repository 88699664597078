import {
  ElasticAppSearchQuery,
  ElasticAppSearchResult,
  ElasticAppSearchResultItem,
  FacetsQuery,
} from '@elastic/app-search-javascript'
import {
  AdditionalSchema,
  PartialProduct,
  StatusLabel,
} from 'framework/common/types'
import { Dictionary } from 'utils/dictionary'
import { getImageUrl4x5 } from 'utils/image'
import tryJSONParse from 'utils/tryJSONParse'
import { getSearchClient } from '.'
import { productGroup, productSearchFields } from './queries'
import { ElasticSearchProduct, MappedFacet } from './types'
import { parseSort, parseUrlFromElastic } from './utils'
import { groupBy } from 'lodash'

/**
 * Maps Elastic search data to a PartialProduct (Used in lists/ categories etc)
 */
export const mapProductResult = (
  p: ElasticAppSearchResultItem<ElasticSearchProduct>,
  locale: string,
  query?: string,
): PartialProduct => {
  const { data } = p
  let name = data.v_name.raw?.replace('Eleiko', '').trim() ?? ''
  const desc = data.bp_desc.raw!
  let bpName =
    data.bp_name.raw?.replace('Eleiko', '').trim().replace(/\\/g, '') ?? ''

  const additionalSchemaJson =
    data.additional_schema?.raw !== undefined ? data.additional_schema?.raw : ''
  let additionalSchema = additionalSchemaJson
    ? tryJSONParse<AdditionalSchema>(additionalSchemaJson)
    : null

  if (!additionalSchema) {
    additionalSchema = {
      bpImgUrl: data.bp_img_url.raw!,
      campaignPrice: data.campaign_price.raw!,
      certificationUrl: data.certification_url.raw!,
      certificationUrls: data.certification_urls?.raw ?? [],
      imageNames: data.image_names.raw!,
      isAvailableOnline: data.is_available_online.raw !== 'false',
      isBundle: data.is_bundle.raw === 'true',
      isVatIncluded: data.is_vat_included.raw !== 'false',
      lowestVariantCampaignPrice: data.lowest_variant_campaign_price.raw!,
      lowestVariantPrice: data.lowest_variant_price.raw!,
      price: data.price.raw!,
    }
  }

  if (typeof additionalSchema.isAvailableOnline === 'undefined') {
    additionalSchema.isAvailableOnline = true
  }

  const campaignPrice = additionalSchema.campaignPrice
  const price = additionalSchema.price
  const hasMultipleVariations = !!(data._group && data._group.length > 0)
  const [image1, image2] = getImages(
    p,
    hasMultipleVariations!,
    additionalSchema,
  )

  const firstDash = name.indexOf(' - ')
  let variant = ''
  if (firstDash > 0 && !hasMultipleVariations) {
    variant = name.substring(firstDash + 3).replace(/\\/g, '')
    name = name.substring(0, firstDash)
  }

  const bpNameFirstDash = bpName.indexOf(' - ')
  if (bpNameFirstDash > 0 && !hasMultipleVariations) {
    bpName = bpName.substring(0, bpNameFirstDash)
  }

  const lowestVariantCampaignPrice =
    additionalSchema.lowestVariantCampaignPrice > 0
      ? additionalSchema.lowestVariantCampaignPrice
      : null

  const lowestVariantPrice = additionalSchema.lowestVariantPrice

  const statusLabelJson =
    data.status_label?.raw !== undefined ? data.status_label?.raw : ''
  // eslint-disable-next-line no-unused-vars
  const statusLabel = tryJSONParse<StatusLabel>(statusLabelJson)

  const isAvailableOnline = additionalSchema.isAvailableOnline
  const inStock = data.in_stock.raw === 'true'
  const label = getLabel(statusLabel, campaignPrice, isAvailableOnline, inStock)

  const product: PartialProduct = {
    id: data.bp_id.raw!,
    variantId: data.v_id.raw!,
    name,
    variant,
    desc,
    variantName: name,
    label,
    href:
      parseUrlFromElastic(data.url.raw!, locale) + (query ? `?q=${query}` : ''),
    imageSrc: image1,
    imageHoverSrc: image2,
    imageHoverAlt: bpName,
    imageAlt: bpName,
    price: hasMultipleVariations
      ? lowestVariantCampaignPrice || lowestVariantPrice
      : campaignPrice || price,
    priceOld: hasMultipleVariations
      ? lowestVariantCampaignPrice
        ? lowestVariantPrice
        : null
      : campaignPrice
        ? price
        : null,
    hasMultipleVariations: hasMultipleVariations || false,
    imageNames: additionalSchema.imageNames,
    isBundle: additionalSchema.isBundle,
    text: data.bp_desc?.raw || '',
    inStock: inStock,
    baseProductName: bpName,
    isAvailableOnline,
    isVatIncluded: additionalSchema.isVatIncluded,
    certificationUrl: additionalSchema.certificationUrl,
    certificationUrls: additionalSchema.certificationUrls,
    seoTags: data.seo_tags?.raw ?? [],
    splitToIndividual: data.split_to_individual?.raw === 'true',
    main: data.main?.raw === 'true',
  }
  return product
}

const getImages = (
  { data }: ElasticAppSearchResultItem<ElasticSearchProduct>,
  hasMultipleVariations: boolean,
  additionalSchema: AdditionalSchema,
) => {
  let image1 = additionalSchema.bpImgUrl
  if (image1) {
    const name = image1
      .substring(image1.lastIndexOf('/'))
      .replace('.jpeg', '.jpg')
    image1 = getImageUrl4x5(name)
  }

  let image2 = data.img_url?.raw!
  if (!image2 && hasMultipleVariations) {
    image2 = (data._group && data._group[0].img_url?.raw) || ''
  }

  if (image2) {
    const name = image2
      .substring(image2.lastIndexOf('/'))
      .replace('.jpeg', '.jpg')
    image2 = getImageUrl4x5(name)
  }

  if (!image1 && !image2) {
    console.error(`Could not find any image for variant ${data.v_id?.raw}`)
    // TODO return placeholder
    return ['', '']
  }

  if (!image2) {
    image2 = image1
  } else if (!image1) {
    image1 = image2
  }

  return [image1, image2]
}

const mapProductFacets = (r: ElasticAppSearchResult<ElasticSearchProduct>) =>
  r.info.facets
    ? Object.entries(r.info.facets).reduce<Dictionary<MappedFacet>>(
        (a, [k, v]) => {
          const d = v[0].data
          if (d.length > 0) {
            a[k] = {
              name: d[0].value.split('##')[0],
              values: d.map(({ count, value }) => ({
                count,
                value: value.split('##')[1],
              })),
            }
          }
          return a
        },
        {},
      )
    : {}

interface ProductSearchProps {
  facetKeys: string[]
  sort: string
  query: string
  category: string | null
}

const buildInitialProductSearchQuery = ({
  query,
  sort,
  facetKeys,
  category,
}: ProductSearchProps) => {
  const q: ElasticAppSearchQuery = {
    group: productGroup,
    filters: {
      all: [{ type: 'product' }],
    },
    sort: parseSort(sort),
    search_fields: productSearchFields,
    query,
  }

  q.facets = facetKeys.reduce<FacetsQuery>((a, f) => {
    a[f] = { type: 'value' }
    return a
  }, {})

  if (category) {
    q.filters!.any = [
      { category_id: [category, category.toLowerCase()] },
      {
        category_ids: [category],
      },
      { parent_category_ids: [category, category.toLowerCase()] },
    ]
  }

  return q
}

interface InitialProductSearchProps extends ProductSearchProps {
  facetsOnly: boolean
  locale: string
}

interface ProductSearchResult {
  result: PartialProduct[]
  facets: Dictionary<MappedFacet>
  totalResults: number
}
const groupResultsByCategory = (
  results: ElasticAppSearchResultItem<ElasticSearchProduct>[],
) => {
  const grouped = groupBy(results, (item) => item.data.category_id.raw)
  return Object.values(grouped)
}
export const initialProductSearch = async (
  props: InitialProductSearchProps,
): Promise<ProductSearchResult> => {
  if (!props.locale) {
    throw Error('Locale not set when trying to do a search')
  }
  const q = buildInitialProductSearchQuery(props)
  q.page = { size: props.facetsOnly ? 0 : 200 }
  const client = getSearchClient(props.locale)
  console.log('buildInitialProductSearchQuery:', q)
  const r = await client.search<ElasticSearchProduct>(props.query, q)
  const groupedResults = groupResultsByCategory(r.results)
  console.log('Grouped Results initresult:', groupedResults)
  return {
    result: groupedResults
      .map((group) =>
        group.map((rr) => mapProductResult(rr, props.locale, props.query)),
      )
      .flat(),
    facets: mapProductFacets(r),
    totalResults: r.info.meta.page.total_results,
  }
}

interface FilterSearchProps extends ProductSearchProps {
  filters: Dictionary<Dictionary<boolean>>
  facets: Dictionary<MappedFacet>
  locale: string
}

export const filteredProductSearch = async ({
  filters,
  facets,
  locale,
  ...props
}: FilterSearchProps): Promise<ProductSearchResult> => {
  if (!locale) {
    throw Error('Locale not set when trying to do a search')
  }
  const q = buildInitialProductSearchQuery(props)
  q.page = { size: 200 }
  if (filters) {
    for (const [k, v] of Object.entries(filters)) {
      if (!v) continue
      const n = facets[k]?.name
      if (!n) {
        console.error(
          `Tried to filter on ${k} but name was not found. Run a query to fetch all facets first`,
        )
        continue
      }
      const f: string[] = []
      for (const [kk, vv] of Object.entries(v)) {
        if (!vv) continue
        f.push(`${n}##${kk}`)
      }
      if (!f.length) continue
      q.filters?.all?.push({ [k]: f })
    }
  }
  const client = getSearchClient(locale)
  const r = await client.search<ElasticSearchProduct>(props.query, q)
  const groupedResults = groupResultsByCategory(r.results)
  console.log('Grouped Results filteredProductSearch:', groupedResults)
  return {
    result: groupedResults
      .map((group) =>
        group.map((rr) => mapProductResult(rr, locale, props.query)),
      )
      .flat(),
    facets: mapProductFacets(r),
    totalResults: r.info.meta.page.total_results,
  }
}

const getLabel = (
  statusLabel: StatusLabel | null,
  campaignPrice: number,
  isAvailableOnline: boolean,
  inStock: boolean,
) => {
  if (!statusLabel) return ''

  if (statusLabel.isNewArrival) {
    return 'newArrival'
  }

  if (!isAvailableOnline) return ''

  if (campaignPrice && inStock) {
    return 'sale'
  }

  if (statusLabel.isLimitedEdition) {
    return 'limitedEdition'
  }

  if (statusLabel.isComingSoon) {
    return 'comingSoon'
  }
  if (statusLabel.isDiscontinued && !inStock) {
    return 'discontinued'
  }
  return ''
}
